import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo k374vkgs3h-editor_css',
    children: 'https://my.timestack.app/423acc01fc05c74bf562e6b877a194b3.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item~k379to73qrg',
        className: 'header3-item',
        children: {
          href: 'https://my.timestack.app/',
          children: [
            {
              children: (
                <>Login / Register</>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner40DataSource = {
  wrapper: { className: 'home-page-wrapper banner4 k3752v2dpn-editor_css' },
  page: { className: 'home-page banner4-page k37521g3r8-editor_css' },
  childWrapper: {
    className: 'banner4-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>Time Tracking &amp; Invoicing for freelancers</>
        ),
        className: 'banner4-title',
      },
      {
        name: 'content',
        className: 'banner4-content k3751b19e2-editor_css',
        children: (
          <>
            <p>
              DECENTRALIZED &amp; FULLY ENCRYPTED - TO PROTECT YOUR PRIVACY<br />
            </p>
          </>
        ),
      },
      {
        name: 'button',
        children: {
          href: '#features',
          type: 'default',
          children: (
            <>
              <p>learn more</p>
            </>
          ),
          className: 'k37522wcjg-editor_css',
          target: '',
        },
      },
    ],
  },
  image: {
    className: 'banner4-image k3753a5aa8m-editor_css',
    children: 'https://www.timestack.app/img/timestack-screen.png',
  },
};
export const Footer01DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer0-wrapper k38lzo73djl-editor_css',
  },
  OverPack: {
    className: 'home-page footer0',
    playScale: 0.05,
    appear: true,
    always: false,
  },
  copyright: {
    className: 'copyright k38lyk7hctc-editor_css',
    children: (<h2>Features</h2>),
  },
};
export const Feature13DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3, always: false },
  imgWrapper: { className: 'content1-img', md: 9, xs: 24 },
  img: {
    children:
      'https://timestack.app/projects.png',
  },
  textWrapper: { className: 'content1-text', md: 15, xs: 24 },
  title: {
    className: 'content1-title',
    style: { marginTop: 60 },
    children: (
      <>Get a bird's eye view of your projects</>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <>
          <p>
              Create and manage all your projects, services &amp; customers in one
              decentralized App ("or DApp"). All data is stored decentrally and is fully encrypted with your private key.<br />
          </p>
          <p style={{ marginTop: 25 }}>
              <h3>Projects highlights</h3>
                <ul style={{ listStyleType: 'disc', marginLeft: 25 }}>
                    <li>Define project tasks and track status as they are completed</li>
                    <li>Track your billable and unbillable time</li>
                    <li>Set fixed price or time-based project budgets</li>
                </ul>
          </p>
      </>
    ),
  },
};
export const Feature14DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3, always: false },
  imgWrapper: { className: 'content1-img', md: 9, xs: 24 },
  img: {
    children:
      'https://timestack.app/timetracking.png',
  },
  textWrapper: { className: 'content1-text', md: 15, xs: 24 },
  title: {
    className: 'content1-title',
    style: { marginTop: 60 },
    children: (
      <>Master your time with simple time tracking</>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>
          Record working hours against specific activities, producing accurate
          statistics of working time. Track time on projects, tasks and clients.
          Compare planned vs. actual project progress.<br />
        </p>
        <p style={{ marginTop: 25 }}>
            <h3>Time Tracking highlights</h3>
              <ul style={{ listStyleType: 'disc', marginLeft: 25 }}>
                  <li>Track your billable and unbillable time</li>
                  <li>Define different task billing rates</li>
                  <li>Enter time using the built-in stopwatch timer</li>
              </ul>
        </p>
      </>
    ),
  },
};
export const Feature12DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3, always: false },
  imgWrapper: { className: 'content1-img', md: 9, xs: 24 },
  img: {
    children:
      'https://timestack.app/invoicing.png',
  },
  textWrapper: { className: 'content1-text', md: 15, xs: 24 },
  title: {
    className: 'content1-title',
    style: { marginTop: 60 },
    children: (
      <>Invoicing that's simply easy</>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>
          Create beautiful invoices based on time tracked per project and
          customer.<br />
        </p>
        <p style={{ marginTop: 25 }}>
            <h3>Invoicing highlights</h3>
              <ul style={{ listStyleType: 'disc', marginLeft: 25 }}>
                  <li>Automatically include unbilled time from projects when you create your invoices</li>
                  <li>Keep track of which invoices are overdue or already paid</li>
                  <li>Use our beautiful invoice template based on german <a href="https://en.wikipedia.org/wiki/DIN_5008" target="_blank" rel="noopener noreferrer">DIN5008</a> writing and layout rules for word processing</li>
              </ul>
        </p>
      </>
    ),
  },
};
export const Feature40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper k38mbodfxlm-editor_css',
  },
  OverPack: {
    className: 'home-page content6 k38ln6p50l7-editor_css',
    gutter: 0,
    always: false,
  },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>How does TimeStack work?</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <>
            <p>
                TimeStack is the first truly <strong>decentralized and fully encrypted</strong> time tracking and
                invoicing application (or "DApp"), build on <b>Blockstack</b>.
                <br />Continue reading to learn more about why TimeStack never stores your identity or data:
            </p>
          </>
        ),
      },
    ],
  },
  img: {
    children: 'https://timestack.app/img/blockstack.png',
    className: 'content6-img k38m8lwnr3t-editor_css',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children: 'https://docs.blockstack.org/assets/posts/logo.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <>
              <p>
                <a name="what-is-a-dapp" />What is a decentralized application or DApp?<br />
              </p>
            </>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <>
              <p>
                Decentralized applications or DApps are a new type of software
                application built with blockchain technology. That means
                TimeStack doesn't store any user information and/or data. Your
                Data is encrypted so that only you have access to it.&nbsp;<br />
              </p>
            </>
          ),
        },
      },
      {
        name: 'block0',
        img: {
          children: 'https://docs.blockstack.org/assets/posts/logo.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <>
              <p>
                What is Blockstack?<br />
              </p>
            </>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <>
              <p>
                Blockstack is a full-stack decentralized computing network that
                enables a new generation of applications where developers and
                users can interact fairly and securely. Blockstack uses
                blockchain technology to build protocols and developer tools
                designed to enable a fair and open Internet that returns digital
                rights to developers and consumers (<a
                  href="https://blockstack.org/try-blockstack"
                  target="_blank" rel="noopener noreferrer"
                >
                  Learn more about Blockstack
                </a>).
              </p>
            </>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children: 'https://docs.blockstack.org/assets/posts/logo.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <>
              <p>
                What problems do Blockstack DApps, like TimeStack, solve for me
                as a user?<br />
              </p>
            </>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <>
                <ol style={{ listStyleType: 'decimal', marginLeft: 25 }}>
                    <li style={{ margin: 0 }}>You control your data, not the application developer.</li>
                    <li style={{ margin: 0 }}>You determine where to store your data.</li>
                    <li style={{ margin: 0 }}>
                      You control which applications and which third parties can
                      access your data.
                    </li>
                </ol>
            </>
          ),
        },
      },
    ],
  },
};
export const Teams00DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams0-wrapper k38uwdy435l-editor_css',
  },
  OverPack: {
    playScale: 0.3,
    className: 'home-page teams0 k38uvts4p6u-editor_css',
    always: false,
  },
  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          children: [
            {
              name: 'image',
              children: 'https://www.timestack.app/img/cornelius.jpg',
              className: 'teams0-image',
            },
            {
              name: 'content',
              children: (
                <>
                  <p>About the DApp-Creator</p>
                </>
              ),
              className: 'teams0-content k38urvdw03h-editor_css',
            },
            {
              name: 'title',
              children: (
                <>
                  <p>Hi, my name is Cornelius!</p>
                </>
              ),
              className: 'teams0-h1 k38usf4oq5-editor_css',
            },
            {
              name: 'content2',
              children: (
                <>
                  <p>
                    IT Specialist &amp; freelance software developer.<br />Get
                    in touch via&nbsp;<a
                      href="https://www.linkedin.com/in/cornelius-bolten/"
                      target="_blank"
                       rel="noopener noreferrer"
                      >
                      LinkedIn
                    </a>
                    <br />Or contact me via{' '}
                    <a href="https://www.dmail.online/" target="_blank" rel="noopener noreferrer">
                      dmail
                    </a>: corneliusbolten.id
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    <br />
                  </p>
                </>
              ),
              className: 'teams0-content k38usnchx1p-editor_css',
            },
          ],
        },
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: {
    className: 'home-page-wrapper pricing0-wrapper k38mggo2ymc-editor_css',
  },
  OverPack: { playScale: 0.3, className: 'home-page pricing0', always: false },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children: 'https://www.timestack.app/img/timestack-laptop.png',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>
              READY TO GET STARTED?<br />
            </p>
          </>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <>
            <p style={{ marginBottom: 25, fontSize: 14 }}>
                TimeStack is built using Blockstack infrastructure, allowing us
                to provide decentralized encrypted storage.
                <a target="_blank"
                    href="https://blockstack.org/try-blockstack"
                     rel="noopener noreferrer">Blockstack ID</a> provides user-controlled login and storage that enable you to take back control of your identity and data.
            </p>
          </>
        ),
        className: 'pricing0-content',
      },
      {
        name: 'button',
        children: {
          // icon: none,
          href: 'https://my.timestack.app/',
          type: 'primary',
          children: (
            <>
              <p>Login / Register</p>
            </>
          ),
          target: '_blank',
        },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://timestack.app/psbuttons.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <>
            <p>Made for Freelancers</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <>
            <p>TimeStack is made BY a Freelancer FOR Freelancers.&nbsp;</p>
          </>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <>
            <p>
              I work continuously on new features to make your life easier.
            </p>
          </>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <span>©2019 TimeStack.app&nbsp;All Rights Reserved</span>
      </>
    ),
  },
};
